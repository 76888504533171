html {
    position: relative;
    min-height: 100%;
}

.break-word {
    word-wrap: break-word;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.readonly-text {
    background-color: #fff;
    cursor: text;
}

.displayed {
    display: block;
    margin: 0 auto;
}

#jumbo {
  padding: 10px;
  background-color: #fff;
}

#password_create {
  padding: 5px;
  text-align: center;
}

.btn-autosize {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.row {
  display: flex;
  text-align: center;
}
/*#row {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}*/

#copybtn {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.col-sm-6 {
    width: 75%;
    margin: 0 auto;
}

.col-sm-10 {
    width: 83.33333333%;
    margin: 0 auto;
}


/*#expiration {
  padding: 10px 10px;
}*/

#copy-clipboard-btn {
  vertical-align: top
}


.modal {
    display:    none;
    position:   fixed;
    z-index:    1000;
    top:        0;
    left:       0;
    height:     100%;
    width:      100%;
    background: rgba( 255, 255, 255, .8 )
                url('../images/spinner.gif')
                50% 50%
                no-repeat;
}

body.loading {
    overflow: hidden;
}

body.loading .modal {
    display: block;
}

.input-group-button {
    position: relative;
    border-collapse: separate;
}

.input-group {
    padding-bottom: 10px;
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    padding-left: 5px;
}

.about-header {
    padding-bottom: 9px;
    padding-left: 100px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
    text-align: left;
}

.navbar-left {
    float: left!important;
    padding-top: 10px;
}
